#sideBar {
    width: 230px;
    height: 100vh;
    background-color: #F3F4F6;
    position: absolute;
    top: 0px;
    left: -275px;
    z-index: 5;
    transition: .4s;
    color: black;

    .closeBar {
        position: absolute;
        top: 5px;
        right: -45px;
        min-width: 10px;
        border-radius: 0px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: black;
        background-color: rgba(0, 0, 0, 0.3);
        border: 1px solid gray;
    }

    section {
        padding-left: 20px;
        margin-top: 20px;

        label {
            font-weight: 600;
            color: black;
        }

        div {
            padding-left: 20px;
            cursor: pointer;
            font-size: 14px;
            color: #474747;

            &:hover {
                color: #a1a1a1;
            }
        }

    }

    .special {
        padding-left: 20px;
        font-weight: 600;
        margin-top: 20px;
        // color: rgb(146, 144, 144);

        div {
            cursor: pointer;

            &:hover {
                
            }
        }
    }

}