@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  display: none;
}

.main{
  width: 100%;
  height: 100vh;
  display: grid;
  place-content: center;
  background-color: rgb(237, 237, 237);
}

@media (max-width:420px) {
  body{
    /* background-color: rgb(245, 248, 252); */
  }
  .main{
    background-color: transparent;
  }
 
}
.input{
  padding-right: 2.25rem;
  border-width: 1px;
}

.input:focus{
  outline: 1px solid #4F46E5;
  border: 1px solid #4F46E5;
}

.input:focus-within{
  outline: 1px solid #4F46E5;
  border: 1px solid #4F46E5;
}

.index{
  z-index: 9999;
}


#z-index{
  z-index: 9999;
}


@media screen and (max-width: 400px) and (min-width: 375px) {
  .due {
      margin-right: 300px;
      background-color: green;
  }
}