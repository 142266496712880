.home {
    width: 100vw;
    height: 100vh;
    max-width: 400px;
    background-color: white;
    

    .headBar{
        width: 100%;
        line-height: 40px;
        text-align: right;
        padding-right: 20px;

        span{
            cursor: pointer;
        }
    }

    h2 {
        text-align: center;
        margin-top: 100px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 100px;
    }

    .btnDiv {
        margin-top: 20px;
        text-align: center;

        .bt{
            width: 200px;
        }
    }
}

@media (max-width:400px) {
   .home{
    
   .rel{
  
    .rel1{
      
        .rel2{
           
            margin-right: 280px;
            margin-left: 280px;
        }
    }
   }
   }
  }