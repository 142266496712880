.home {
    width: 100vw;
    height: 100vh;
    max-width: 400px;
    background: #0F55A7;
    position: relative;
    max-height: 850px;
    overflow: hidden;

    .qrLoader {
        width: 100%;
        height: 100vh;
        background-color: rgba(7, 7, 7, 0.5);
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 10;
        display: grid;
        place-content: center;
    }

    .alertCon {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 20;
        display: grid;
        place-content: center;

        .alertBox {
            width: 300px;
            // height: 200px;
            background-color: white;
            box-shadow: 1px 1px 10px rgb(102, 102, 102);
            padding: 20px;

            section {
                font-weight: 600;
                margin-bottom: 20px;
            }

            .but1 {
                background-color: rgb(2, 160, 2);
            }

            .but2 {
                margin-left: 30px;
            }
        }
    }


    #inner {
        width: 100%;
        height: 100vh;
        background: white;
        position: absolute;
        bottom: 0px;


        .innerScroll {
            margin-top: 15px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            height: 95vh;
        }


        .but1 {
            position: absolute;
            top: 2px;
            left: 2px;
            color: #D9D9D9;
            border: none;
        }

        .head {
            text-align: center;
            width: 70%;
            margin: auto;
            margin-top: 20px;

            div {
                color: #474747;
                font-weight: 700;
                font-size: 17px;
                letter-spacing: .25px;
            }

            section {
                font-weight: 500;
                margin-top: 8px;
                color: #797979;
                font-size: 12px;
            }
        }

        form {
            width: 80%;
            margin: auto;
            margin-top: 40px;

            .form-group {
                margin-top: 20px;
                position: relative;

                label {
                    font-weight: 600;
                    color: #474747;
                    margin-bottom: 5px;
                    font-size: 15px;
                    font-family: 'Roboto';
                }

                input {
                    border-color: #C6C6C6;
                    line-height: 30px;
                    border-radius: 10px;
                    font-size: 13px;
                    font-weight: 500;

                    &::placeholder {
                        color: #C6C6C6;
                    }
                }

                .selectProduct {
                    position: absolute;
                    width: 100%;
                    height: 200px;
                    background-color: white;
                    box-shadow: 0px 0px 10px #C6C6C6;
                    z-index: 2;
                    border-radius: 10px;
                    margin-top: 10px;
                    padding: 10px;
                    padding-top: 0px;
                    padding-bottom: 15px;
                    overflow-y: scroll;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                }


                .grid1 {
                    display: grid;
                    grid-template-columns: 1fr 55px 55px;

                    .d1 {
                        padding-right: 8px;
                    }

                    .d2 {
                        text-align: center;

                        .bt3 {
                            background: #0B69D8;
                            color: white;
                            height: 42px;
                            width: 50px;
                            min-width: 20px;
                            display: grid;
                            place-content: center;
                            border-radius: 10px;
                        }
                    }

                }


                .grid2 {
                    display: grid;
                    grid-template-columns: 1fr 1fr;

                    .bt1 {
                        width: 90%;
                        height: 45px;
                        border-radius: 10px;
                        text-transform: none;
                        background-color: #0B69D8;
                    }

                    .bt2 {
                        width: 90%;
                        height: 45px;
                        border-radius: 10px;
                        border: 1px solid #C6C6C6;
                        color: #C6C6C6;
                        text-transform: none;
                        background-color: white;
                        box-shadow: none;
                    }
                }


            }

            .select {
                position: relative;

                input {
                    padding-left: 40px;
                }

                .icon {
                    position: absolute;
                    bottom: 5px;
                    left: 8px;
                    font-size: 30px;
                    color: #C6C6C6;
                }
            }
        }

        .genBox {
            text-align: center;
            margin-top: 50px;
            width: 100%;

            .generate {
                width: 80%;
                border-radius: 10px;
                text-transform: none;
                height: 45px;
                background-color: #0B69D8;
            }
        }


    }
}

@media (max-width:500px) {
    .printQr {
        #inner {
            background-color: white;
        }
    }
}