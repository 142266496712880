.qrHistory {
    width: 100vw;
    height: 100vh;
    max-width: 400px;
    background: #0F55A7;
    position: relative;
    max-height: 850px;
    overflow: hidden;

    .qrHeader {
        width: 95%;
        margin: auto;
        line-height: 5vh;
        margin-bottom: 5px;

        .btn2 {
            padding: 0px;
            min-width: 40px;
            color: white;
        }
    }

    .tableInner {
        width: 95%;
        height: 95vh;
        margin: auto;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        .tableCon {
            width: 100%;
            height: 50px;
            background-color: white;
            border-top: 1px solid gray;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            place-items: center;
        }
    }
}