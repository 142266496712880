.gateHome {
    width: 100vw;
    min-height: 100vh;
    max-width: 400px;
    background: white;
    position: relative;
    overflow: hidden;
    padding-top: 50px;

    .qrCon {
        width: 204px;
        height: 204px;
        border: 2px solid black;
        margin: auto;
        margin-top: 20px;
    }

    .count {
        width: 80%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: 500;
    }

    .scannedQr {
        width: 80%;
        margin: auto;
        height: 18vh;
        overflow-y: scroll;

        .qrNumber {
            font-size: 14px;

            .clearBt {
                font-weight: bold;
                position: relative;
                top: -2px;
                margin-left: 8px;
                font-size: 20px;
            }
        }
    }

    .dispatchForm {
        width: 100%;
        margin: auto;
        margin-top: 20px;
        max-width: 400px;
        position: fixed;
        bottom: 0px;
        background-color: white;
        padding: 15px 40px 20px 40px;
        border-top: 1px dotted gray;

        .form-group {
            margin-top: 15px;

            input {
                height: 35px;
                font-size: 14px;
            }
        }

        .btnCon {
            margin-top: 30px;
            text-align: center;

            .dispatchBt {
                text-transform: none;
            }
        }
    }
}