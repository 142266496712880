.Gatekeeper_login {
    width: 100vw;
    height: 100vh;
    max-width: 400px;
    background: #F6FAFF;
    border-bottom-width: 1px;
    // --tw-border-opacity: 1;
    // border-color: rgb(229 231 235 / var(--tw-border-opacity));
    // --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / .1), 0 1px 2px -1px rgb(0 0 0 / .1);
    // --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    // box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
   

    .header {
        // margin-top: 100px;
        text-align: center;
        

        h1 {
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 600;
            --tw-text-opacity: 1;
            color: rgb(17 24 39 / var(--tw-text-opacity));
            font-family: 'Roboto', sans-serif;
           
        }

        span {
            font-family: 'Roboto', sans-serif;
            font-weight: bold;
            font-size: 14px;
            color: #474747;
            letter-spacing: .5px;
            position: relative;
            left: 55px;
            top: -20px;
        }
        p{
            font-family: 'Roboto', sans-serif;
        }
    }

    form {
        width: 80%;
        margin: auto;
        color: #474747;
        font-weight: 600;
        // font-family: 'Roboto', sans-serif;
        letter-spacing: .5px;
        margin-top: 50px;

        .form-group {
            margin-top: 25px;
            position: relative;

            label {
                margin-bottom: 5px;
            }

            input {
                // line-height: 33px;
                // border-color: #C6C6C6;
                font-size: 14px;
                // letter-spacing: .7px;
                padding-left: 50px;
                padding-right: 50px;
                font-weight: 400;
              

                &::placeholder {
                    color: #ACB4C4;
                   font-family: 'Roboto', sans-serif;
                   font-weight: 100;
                }
            }

            .icon1 {
                position: absolute;
                bottom: 12px;
                left: 13px;
                color: #C6C6C6;
            }

            .icon2 {
                position: absolute;
                bottom: 11px;
                right: 20px;
                color: #C6C6C6;
                cursor: pointer;
            }

            .error {
                color: red;
                font-size: 10px;
                position: absolute;
                left: 0px;
                bottom: -16px;
                font-weight: 400;
            }
        }

        .btnLogin {
            width: 100%;
            text-align: center;
            margin-top: 30px;

            .bt {
                width: 100%;
                line-height: 30px;
                border-radius: 10px;
                text-transform: none;
                font-weight: 500;
                letter-spacing: 1px;
                font-size: 15px;
            }
        }
    }
}


@media (max-width:500px) {
    .Gatekeeper_login {
        background-color: white;
    }
}