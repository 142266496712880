.packerHome {
    width: 100vw;
    height: 100vh;
    max-width: 400px;
    background: white;
    position: relative;
    overflow: hidden;
    padding-top: 50px;

    .qrCon {
        width: 204px;
        height: 204px;
        border: 2px solid black;
        margin: auto;
        margin-top: 20px;
    }

    .masterText1 {
        color: red;
        text-align: center;
        margin-top: 15px;
        font-size: 14px;
        font-weight: 500;
    }

    .masterText2 {
        width: 80%;
        margin: auto;
        color: green;
        margin-top: 15px;
        font-size: 14px;
        font-weight: 500;
    }

    .master_serial {
        font-weight: bold;
        width: 80%;
        margin: auto;
        margin-top: 10px;

        .clearIcon {
            position: relative;
            top: -1.5px;
        }
    }

    .count {
        width: 80%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
        font-weight: 500;
    }

    .scannedQr {
        width: 80%;
        margin: auto;
        height: 40vh;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }

        .qrNumber {
            font-size: 14px;

            .clearBt {
                font-weight: bold;
                position: relative;
                top: -2px;
                margin-left: 8px;
                font-size: 20px;
            }
        }
    }

    .masterBtCon {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 20px;

        .masterBtn {
            text-transform: none;
        }
    }

    .linkGoCon {
        width: 100%;
        position: absolute;
        bottom: 20px;

        div {
            margin: auto;
            width: 80%;
            position: relative;

            .go {
                text-transform: none;
            }

            .link {
                text-transform: none;
                position: absolute;
                right: 0px;
            }
        }

    }

}