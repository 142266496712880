.product {
    width: 100%;
    height: 50px;
    display: grid;
    grid-template-columns: 50px 1fr;
    cursor: pointer;
    margin-top: 15px;

    img {
        width: 50px;
        height: 50px;
    }

    div {
        padding-left: 15px;
        padding-top: 6px;
        font-weight: 500;

        section {
            color: #474747;
            font-size: 14px;
        }

        span {
            color: #797979;
            position: relative;
            font-size: 12px;
            top: -5px;
        }
    }
}